.noMeetingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.noMeetingCircle {
  /* position: absolute; */
  top: 50%;
  left: 50%;
  border-radius: 200px;
  width: 352px;
  height: 352px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noMeetingCircle > div {
  color: #909fa4;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
}
