.dayIndicatorTableHeadDate {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--visionable-dark-blue);
}
.dayIndicatorDateSelected {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: white;
}
.dayIndicatorTableHeadDay {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--visionable-font-beige);
}
