.meetingsOverallPage {
  display: flex;
  height: calc(100vh - 68px);
  width: 100%;
  flex-direction: column;
  overflow: auto;
  background-color: #fafafa;
}

 

.meetingsHeader {
  font-family: Nunito Sans;
  padding-left: 41px;
}

.date_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  font-size: 25px;
  /* padding-bottom: 25px; */
  font-family: Nunito Sans;
  font-weight: 700;
  font-style: normal;
  line-height: 21.6px;
  color: #042349;
}

.state_View_And_Date_Container {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.date_Container_Box {
  padding-top: 15px;
  padding-bottom: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.date_Arrow_Buttons:hover {
  cursor: pointer;
}

.meetingsCreateMeetingContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.meetings_View_State {
  border: 2px solid #e6e6e6;
  background: #fafafa;
}

.line_break {
  border-top: 2px solid #e6e6e6;
}
