.meetingCardPaperContainer {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  cursor:pointer;
}

.meetingCardMeetingNameSection {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 120%;
}
.meetingCardOrganizerSection {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  color: var(--visionable-dark-blue);
}

.meetingCardTimesSection {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  color: var(--visionable-dark-blue);
}
