.meetingJoinButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  cursor: pointer;
  padding-left: 5px;
}

.meetingJoinButton {
  padding: 14px 24px;
  width: 100%;
 
  
  max-height: 37px;
  color: white;
  background: #1271ee;
  border-radius: 4px;
  border: 1.04167px solid #1271ee;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
}
