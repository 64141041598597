.settings-content_layout {
  flex-grow: 1;
  height: 66vh;
  display: flex;
}

.settings-tab-list {
  flex: 1;
}

.settings-content {
  flex: 3;
}
