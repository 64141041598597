.select-start-time {

    box-sizing: border-box;
    border-radius: 4px;
    width: 175px;
    height: 50px;
    padding-left: 17px;
    font-family: Nunito Sans;
    font-style: normal;
    font-size: 18px;
}

.input-end-time {
    background: #ffffff;

    box-sizing: border-box;
    border-radius: 4px;
    width: 300px;
    height: 50px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding-left: 20px;
}

.input-end-time-error {
    background: #ffffff;
    border:2.2px solid var(--error);
    box-sizing: border-box;
    border-radius: 4px;
    width: 300px;
    height: 50px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding-left: 20px;
}

.select-end-time {

    box-sizing: border-box;
    border-radius: 4px;
    width: 175px;
    height: 50px;
    padding-left: 17px;
    font-family: Nunito Sans;
    font-style: normal;
    font-size: 18px;
}

.select-end-time-error {
    border: 1px solid var(--error);
    box-sizing: border-box;
    border-radius: 4px;
    width: 185px;
    height: 50px;
    padding-left: 17px;
    font-family: Nunito Sans;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    color:red;
}

.description-with-moderators {
    width: 100%;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}



.input-start-time {
    background: #ffffff;
   
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 20px;
    height: 50px;
    width: 100%;
    /* margin: 8px 0; */
}

.input-start-time-error {
    background: #ffffff;
    border: 2.3px solid var(--error);
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 20px;
    height: 50px;
    width: 100%;
    color: var(--error);
    
}