.acceptButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  color: var(--visionable-mid-blue);
}
.selectorTag {
  display: flex;
  color: var(--visionable-mid-blue);
  text-align: center;
}

.selectorButton {
  max-height: 38px;
  min-width: 180px;
  width: 100%;
}
